import Iqf from "../assets/projects/1.png";
import Covid from "../assets/projects/2.png";
import Nflix from "../assets/projects/3.png";
import Movie from "../assets/projects/4.png";
import Sig from "../assets/projects/5.png";
import Shop from "../assets/projects/6.png";
import Ticket from "../assets/projects/7.png";
import Temu from "../assets/projects/8.png";
import Job from "../assets/projects/9.png";

export const data = [
  {
    id: 1,
    name: "Company Profile",
    image: Iqf,
    github: "https://iqf.or.id/",
    live: "https://iqf.or.id/",
  },
  {
    id: 2,
    name: "Covid APP React",
    image: Covid,
    github: "https://github.com/ziyad1412/Covid-App",
    live: "https://ziyad1412.github.io/Covid-App/",
  },
  {
    id: 3,
    name: "Streaming Film Laravel",
    image: Nflix,
    github: "https://github.com/ziyad1412/NFlix",
    live: "https://nflix.kajicode.my.id/",
  },
  {
    id: 4,
    name: "Movie Database React",
    image: Movie,
    github: "https://github.com/ziyad1412/Frontend_Web",
    live: "https://ziyad1412.github.io/Frontend_Web/",
  },
  {
    id: 5,
    name: "SIG Kesehatan Depok",
    image: Sig,
    github: "https://github.com/ziyad1412/React-SIG",
    live: "https://sig-kesehatan-depok.kajicode.my.id/",
  },
  {
    id: 6,
    name: "E-Commerce Laravel",
    image: Shop,
    github: "http://shop.kajicode.my.id/",
    live: "http://shop.kajicode.my.id/",
  },
  {
    id: 7,
    name: "Ticketing App",
    image: Ticket,
    github: "https://staging-aero-swift.netlify.app/",
    live: "https://staging-aero-swift.netlify.app/",
  },
  {
    id: 8,
    name: "Company Profile Web",
    image: Temu,
    github: "https://temugrowtryout.com/",
    live: "https://temugrowtryout.com/",
  },
  {
    id: 9,
    name: "Job Portal Web",
    image: Job,
    github: "https://bwa-job-hunt.vercel.app/",
    live: "https://bwa-job-hunt.vercel.app/",
  },
];
