import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 via-black to-gray-800 text-gray-300"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-2">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-sky-600">
              About Me
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>
              Hi. I'm Abdurrahman Ziyad, nice to meet you. Please take a look
              around.
            </p>
          </div>
          <div>
            <p className="font-medium">
              Final year student of Informatics Engineering who has an interest
              in programming. Have an understanding of application and web
              development, as well as database management. With over one year of
              experience in web development, Experienced in executing diverse
              website and application development projects, showcasing
              adaptability and proficiency in learning new technologies.
              Possesses excellent analytical and interpersonal skills,
              contributing effectively to collaborative environments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
